import React, { useEffect } from 'react';

import {
  leftBarThemeImageTypes,
  leftSideBarThemeTypes,
  topBarThemeTypes
} from 'components/Layout/constants/layout';

// Layout Related Components
import Footer from 'components/Layout/Common/Footer';
import Sidebar from 'components/Layout/Common/Sidebar';

import {
  changeLeftSidebarTheme,
  changeLeftSidebarThemeImage,
  changeLeftSidebarType,
  changeTopbarTheme
} from 'components/Layout/Common/layoutHelperFunctions';

const leftSideBarTheme = leftSideBarThemeTypes.DARK;
const leftSideBarThemeImage = leftBarThemeImageTypes.NONE;
const leftSideBarType = 'default';
const topbarTheme = topBarThemeTypes.LIGHT;
const Layout = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (leftSideBarTheme) {
      changeLeftSidebarTheme(leftSideBarTheme);
    }
  }, [leftSideBarTheme]);

  useEffect(() => {
    if (leftSideBarThemeImage) {
      changeLeftSidebarThemeImage(leftSideBarThemeImage);
    }
  }, [leftSideBarThemeImage]);

  useEffect(() => {
    if (leftSideBarType) {
      changeLeftSidebarType(leftSideBarType);
    }
  }, [leftSideBarType]);

  useEffect(() => {
    if (topbarTheme) {
      changeTopbarTheme(topbarTheme);
    }
  }, [topbarTheme]);
  return (
    <React.Fragment>
      <div id='layout-wrapper'>
        <Sidebar />
        <div className='main-content'>{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;

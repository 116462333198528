import { appWithTranslation } from 'next-i18next';

import 'global-styles.css';
import 'material-icons/iconfont/material-icons.css';
import 'public/scss/theme.scss';
import 'react-datepicker/dist/react-datepicker.css';

import WithAuth from 'components/Auth/WithAuth';
import getLayout from 'components/Layout/LayoutConfiguration';
import nextI18NextConfig from 'next-i18next.config';

import 'components/Editor/index.css';

function MyApp({ Component, pageProps, router }) {
  const layout = getLayout(router.pathname);

  return (
    <WithAuth router={router}>
      {layout({ children: <Component {...pageProps} /> })}
    </WithAuth>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);

const topBarThemeTypes = {
  LIGHT: 'light',
  DARK: 'dark',
  COLORED: 'colored'
};

const leftBarThemeImageTypes = {
  NONE: 'none',
  IMG1: 'img1',
  IMG2: 'img2',
  IMG3: 'img3',
  IMG4: 'img4'
};

const leftSideBarThemeTypes = {
  LIGHT: 'light',
  COLORED: 'colored',
  DARK: 'dark',
  WINTER: 'winter',
  LADYLIP: 'ladylip',
  PLUMPLATE: 'plumplate',
  STRONGBLISS: 'strongbliss',
  GREATWHALE: 'greatwhale'
};

export { leftBarThemeImageTypes, leftSideBarThemeTypes, topBarThemeTypes };

import Link from 'next/link';
import router, { withRouter } from 'next/router';
import { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';

import LEFT_NAVIGATION_MENU from 'config/leftNav';
import { PLANNER_PORTAL_URL } from 'services/connections.service';
import { removeAuthIdentity } from 'services/identity.service';
import { PAGE_NAME, getPageURL } from 'services/opsPortal.service';

const SidebarContent = (props) => {
  const getShowNestedChildrenClassName = (child, showNestedChildren) => {
    if (!child) return '';
    if (showNestedChildren) return 'has-arrow';
    return 'mm-show has-arrow';
  };

  const [leftNavigationMenu, setLeftNavigationMenu] = useState([]);

  useEffect(() => {
    setLeftNavigationMenu(LEFT_NAVIGATION_MENU);
  }, []);

  return (
    <SimpleBar className='h-100'>
      <div id='sidebar-menu'>
        <ul
          className='metismenu list-unstyled'
          id='side-menu'
        >
          <div className='planner-portal-btn'>
            <li>
              <Link href={PLANNER_PORTAL_URL}>
                <i className='bx bx-briefcase'></i>
                <span className='items-center self-center'>Planner Portal</span>
              </Link>
            </li>
          </div>
          {leftNavigationMenu.map((item, index) => {
            const {
              children,
              icon,
              name,
              route,
              show = true,
              showNestedChildren
            } = item;
            return (
              show && (
                <li
                  key={index}
                  className={router.asPath === route ? 'active' : ''}
                >
                  <Link
                    href={route || props.router.asPath}
                    className={getShowNestedChildrenClassName(
                      children,
                      showNestedChildren
                    )}
                    onClick={() => {
                      Object.assign(item, {
                        showNestedChildren: !showNestedChildren
                      });
                      setLeftNavigationMenu(leftNavigationMenu);
                    }}
                  >
                    {icon}
                    <span>{name}</span>
                  </Link>
                  {children && showNestedChildren && (
                    <ul className='sub-menu'>
                      {children.map(
                        (
                          { route: subMenuRoute, name: subMenuName },
                          index3
                        ) => (
                          <li key={index3}>
                            <Link
                              href={subMenuRoute}
                              className={
                                props.router.pathname === subMenuRoute
                                  ? 'mm-active'
                                  : ''
                              }
                            >
                              {subMenuName}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </li>
              )
            );
          })}
          <div>
            <li>
              <Link
                href={getPageURL({
                  pageName: PAGE_NAME.HOME.label
                })}
                onClick={() => {
                  removeAuthIdentity();
                  router.reload();
                }}
              >
                <i className='bx bx-log-out-circle'></i>
                <span>LOGOUT!</span>
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </SimpleBar>
  );
};

export default withRouter(SidebarContent);

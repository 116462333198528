import EmptyLayout from './EmptyLayout';
import Layout from './Layout';

const LayoutConfig = [
  {
    path: '/login',
    layout: EmptyLayout
  },
  {
    path: '/',
    layout: Layout
  },
  {
    path: '/zones',
    layout: Layout
  },
  {
    path: '',
    layout: EmptyLayout
  }
];

const getLayout = (path) => {
  const config = LayoutConfig.find((conf) => path.includes(conf.path));
  if (config) return config.layout;
  return EmptyLayout;
};

export default getLayout;

import Image from 'next/image';
import React from 'react';

import SidebarContent from 'components/Layout/Common/SidebarContent';

const Sidebar = () => (
  <React.Fragment>
    <div className='vertical-menu'>
      <div className='navbar-brand-box'>
        <div className='logo logo-light mt-4 mx-auto w-24'>
          <span className='logo-sm'>
            <Image
              src={'/images/hafla-logo.png'}
              alt='logo'
              height={45}
              width={45}
            />
          </span>
          <span className='logo-lg'>
            <Image
              alt='logo'
              height={32}
              priority
              src={'/images/logo.svg'}
              width={100}
            />
          </span>
        </div>
      </div>
      <div
        data-simplebar
        className='h-100'
      >
        <SidebarContent />
      </div>
      <div className='sidebar-background'></div>
    </div>
  </React.Fragment>
);

export default Sidebar;

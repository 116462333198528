import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => (
  <React.Fragment>
    <footer className='footer position-fixed'>
      <Container fluid={true}>
        <Row>
          <Col md={6}>{new Date().getFullYear()} © Hafla</Col>
          <Col md={6}>
            <div className='text-sm-end d-none d-sm-block'>
              Powered by: Evinops Ltd
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  </React.Fragment>
);

export default Footer;

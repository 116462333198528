import _ from 'lodash';

import {
  COOKIE_MAP,
  getCookie,
  removeCookie,
  setCookie
} from '@/services/cookie.service';

export const getAuthIdentity = () => {
  const auth = getCookie({
    name: COOKIE_MAP.OPS_PORTAL_USER_IDENTITY
  });
  if (auth?.token) {
    return auth;
  }
  return null;
};

export const setAuthIdentity = (authObject) =>
  setCookie({
    name: COOKIE_MAP.OPS_PORTAL_USER_IDENTITY,
    data: authObject
  });

export const removeAuthIdentity = () =>
  removeCookie({ name: COOKIE_MAP.OPS_PORTAL_USER_IDENTITY });

export const getAuthToken = () => getAuthIdentity()?.token || null;

export const isAuthenticated = () => {
  const token = getAuthToken();
  return Boolean(!_.isEmpty(token));
};

export const getPlannerId = () => {
  const auth = getAuthIdentity();
  return auth?.id;
};

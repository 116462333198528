import security from 'components/Auth/Security';
import { isBrowser } from 'lib/utils';
import { isAuthenticated } from 'services/identity.service';
import { PAGE_NAME, getPageURL } from 'services/opsPortal.service';

const { isProtected } = security;

const WithAuth = ({ router, children }) => {
  const loginPageURL = getPageURL({ pageName: PAGE_NAME.LOGIN.label });
  const portalHomePageURL = getPageURL({ pageName: PAGE_NAME.HOME.label });

  if (isBrowser()) {
    const isUnAuthenticatedUserAccessingProtectedPages =
      !isAuthenticated() && isProtected(router.pathname);
    if (isUnAuthenticatedUserAccessingProtectedPages) {
      router.replace(loginPageURL).then(() => router.reload());
      return null;
    }

    const isAuthenticatedUserAccessingLoginPages =
      isAuthenticated() && !isProtected(router.pathname);
    if (isAuthenticatedUserAccessingLoginPages) {
      router.replace(portalHomePageURL).then(() => router.reload());
      return null;
    }
  }

  return children;
};

export default WithAuth;

import { getPageURL, PAGE_NAME } from 'services/opsPortal.service';

const MENU = [
  {
    icon: <i className='bx bxs-home'></i>,
    name: 'Home',
    route: '/'
  },
  {
    icon: <i className='bx bxs-dashboard'></i>,
    name: 'Dashboards',
    route: getPageURL({ pageName: PAGE_NAME.DASHBOARDS.label })
  },
  {
    icon: <i className='bx bxs-shopping-bag'></i>,
    name: 'Event Carts',
    route: getPageURL({ pageName: PAGE_NAME.EVENT_CARTS.label }),
    show: false
  },
  {
    icon: <i className='fas fa-box'></i>,
    name: 'Orders',
    route: getPageURL({ pageName: PAGE_NAME.ORDERS.label })
  },
  {
    icon: <i className='fas fa-user-tie'></i>,
    name: 'Partners',
    route: getPageURL({ pageName: PAGE_NAME.PARTNERS.label })
  },
  {
    icon: <i className='bx bxs-box'></i>,
    name: 'Products',
    route: getPageURL({ pageName: PAGE_NAME.PRODUCTS.label })
  },
  {
    icon: <i className='bx bxs-discount'></i>,
    name: 'Promo Codes',
    route: getPageURL({ pageName: PAGE_NAME.PROMO_CODES.label })
  },
  {
    children: [
      {
        name: 'Collections Listing',
        order: 1,
        route: getPageURL({ pageName: PAGE_NAME.COLLECTIONS.label })
      },
      {
        name: 'Collection Management',
        order: 2,
        route: getPageURL({ pageName: PAGE_NAME.COLLECTION_MANAGEMENT.label })
      }
    ],
    icon: <i className='bx bxs-bookmark-plus'></i>,
    name: 'Collections'
  },
  {
    icon: <i className='fas fa-download'></i>,
    name: 'DataExports',
    route: getPageURL({ pageName: PAGE_NAME.DATA_EXPORTS.label })
  },
  {
    icon: <i className='fas fa-user-cog'></i>,
    name: 'Planners',
    route: getPageURL({ pageName: PAGE_NAME.PLANNERS.label })
  },
  {
    children: [
      {
        name: 'Category Listing',
        order: 1,
        route: getPageURL({ pageName: PAGE_NAME.CATEGORIES.label })
      },
      {
        name: 'Category',
        order: 2,
        route: getPageURL({ pageName: PAGE_NAME.CATEGORY_MANAGEMENT.label })
      },
      {
        name: 'Master Attribute Groups',
        order: 3,
        route: getPageURL({
          pageName: PAGE_NAME.CATEGORY_MASTER_ATTRIBUTE_GROUP.label
        })
      },
      {
        name: 'Master Attributes',
        order: 4,
        route: getPageURL({
          pageName: PAGE_NAME.CATEGORY_MASTER_ATTRIBUTE.label
        })
      },
      {
        name: 'Enum',
        order: 5,
        route: '#'
      },
      {
        name: 'Units',
        order: 6,
        route: '#'
      }
    ],
    icon: <i className='fas fa-th-list'></i>,
    name: 'Category Management'
  }
];

const LEFT_NAVIGATION_MENU = MENU.map((item, index) => ({
  ...item,
  order: index + 1
}));

export default LEFT_NAVIGATION_MENU;

import { TOAST_MESSAGE_COLOR_CODE } from '@/config/common';

export const isBrowser = () => typeof window !== 'undefined';

export const parseAPIResponse = ({
  data: { status: dataStatus = true, message: dataMessage, entity } = {},
  message: errorMessage,
  status: apiStatus
}) => {
  const successStatus = dataStatus && apiStatus;
  const toastMessage = dataMessage || errorMessage;
  const toastColor = successStatus
    ? TOAST_MESSAGE_COLOR_CODE.SUCCESS
    : TOAST_MESSAGE_COLOR_CODE.ERROR;
  return {
    entity,
    successStatus,
    toastColor,
    toastMessage
  };
};
